import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import { Button, useFormProps } from 'core/components';
import ActionAlertModal from 'core/components/ActionAlertModal';
import { additionalQuotesValues } from 'modules/shared/bidDefaultValues';

import './styles.scss';

interface INoteModal {
  onClose: Function;
  onCancel: Function;
  notesText?: string;
  canEdit?: boolean;
  canRestore?: boolean;
  genericTitle?: string;
  fieldName?: string;
  deleteConfirmationMessage?: string;
}

const iosCopyToClipboard = (el: any) => {
  const oldContentEditable = el.contentEditable,
    oldReadOnly = el.readOnly,
    range = document.createRange();

  el.contentEditable = true;
  el.readOnly = false;
  range.selectNodeContents(el);

  const s = window.getSelection();
  s!.removeAllRanges();
  s!.addRange(range);

  el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

  el.contentEditable = oldContentEditable;
  el.readOnly = oldReadOnly;

  document.execCommand('copy');
};

export const NoteModal = ({
  onClose,
  onCancel,
  notesText,
  canEdit = true,
  canRestore = false,
  genericTitle = 'Notes',
  fieldName = 'additionalNote',
  deleteConfirmationMessage = 'Are you sure you want to clear the notes?',
}: INoteModal) => {
  const { onChange, formValues } = useFormProps();
  const [note, setNote] = useState(
    notesText ? notesText.trim() : formValues[fieldName]
  );

  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showRestoreModal, setShowRestoreModal] = useState(false);

  const onClickClose = useCallback(() => {
    onChange(fieldName, note);
    onClose(note);
  }, [note]);

  const onClickCopy = useCallback(() => {
    if (!navigator.clipboard) {
      iosCopyToClipboard(document.getElementById('textAreaToCopy'));
      toast.info(genericTitle + ' Copied to Clipboard');
      return;
    }

    navigator.clipboard.writeText(note);
    toast.info(genericTitle + ' Copied to Clipboard');
    // eslint-disable-next-line
  }, [note]);

  const onConfirmDeletion = () => {
    setShowAlertModal(false);
    setNote('');
    // onChange(fieldName, '');
  };

  const onConfirmRestore = () => {
    setShowRestoreModal(false);
    setNote(additionalQuotesValues.serviceFooter);
    // onChange(fieldName, additionalQuotesValues.serviceFooter);
  };

  return (
    <div className="additional-notes-root">
      <div className="additional-notes-content-div">
        <div className="additional-notes-title-container">
          <h3>{genericTitle}</h3>
        </div>
        <div className="text-area-container">
          <textarea
            disabled={!canEdit}
            id="textAreaToCopy"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            // onBlur={() => onChange(fieldName, note)}
          />
        </div>
        <div className="actions-area-container">
          {canEdit && (
            <>
              {canRestore && (
                <div
                  className="action-container"
                  onClick={() => setShowRestoreModal(true)}
                >
                  <i className="refresh-icon" />
                  <span>Reset</span>
                </div>
              )}
              <div
                className="action-container"
                onClick={() => setShowAlertModal(true)}
              >
                <i className="trash-icon" />
                <span>Clear</span>
              </div>
            </>
          )}
          <div className="action-container" onClick={onClickCopy}>
            <i className="copy-icon" />
            <span>Copy</span>
          </div>
        </div>
      </div>

      <div className="additional-notes-done-button">
        {canEdit && (
          <button className="button-light" onClick={() => onCancel()}>
            CANCEL
          </button>
        )}
        <button className="button" onClick={() => onClickClose(note)}>
          DONE
        </button>
      </div>

      <ActionAlertModal
        title=""
        message={deleteConfirmationMessage}
        onConfirm={onConfirmDeletion}
        onDismiss={() => setShowAlertModal(false)}
        isVisible={showAlertModal}
      />

      <ActionAlertModal
        title=""
        message="Are you sure you want to restore the service footer?"
        onConfirm={onConfirmRestore}
        onDismiss={() => setShowRestoreModal(false)}
        isVisible={showRestoreModal}
      />
    </div>
  );
};
